import React, { useState } from 'react'
import { shape, string, array, func } from 'prop-types'
import SaleBanner, { SaleBannerTONABTest } from '@ycos/component-sale-banner'
import { Grid } from '@ycos/component-grid'
import { OptimizelyFeature, withOptimizely } from '@ynap/optimizely-utils'
import TakeOver from '../component-takeover'
import { hasTag } from '@ycos/coremedia-renderer'

// TON AB Test component for Sale Banner Test
// Imports AB Test component from shared ycos component (variation)
// Uses osc-takeover component from TON (control)
// Setup in coremedia is
// - First item control
// - Second item variation
const TONSaleBannerABTest = (props) => {
  const { item, onTargetClick, locale = {}, config = {}, brand, forStoryBookUseOnly } = props
  const optimizelyTracking = (optimizely) => {
    optimizely?.track('hp_to_sale_plp')
    optimizely?.track('global_sale_plp')
  }

  // TON uses 2 components for control that change along the sale period:
  // - A normal takeover
  // - A skinny banner
  // So when the component changes we can adapt.
  const getControl = ({ tags, takeoverItem, locale, config, brand, optimizelyProps }) => {
    const useSkinnyBanner = hasTag(tags, 'useSkinnyBanner')

    return useSkinnyBanner ? (
      <SaleBanner item={takeoverItem} onTargetClick={() => optimizelyTracking(optimizelyProps?.optimizely)} locale={locale} config={config} brand={brand} />
    ) : (
      <TakeOver item={takeoverItem} config={config} extraOnClick={() => optimizelyTracking(optimizelyProps?.optimizely)} />
    )
  }

  return (
    <OptimizelyFeature feature={['web00526_shopbytextcarousel_homepage_desktop', 'web00527_shopbytextcarouselhomepage_mobile']} {...props}>
      {(optimizelyProps) => {
        const { isEnabled, variationKey } = optimizelyProps
        // Wait till Optimizely loads
        const [isOptimizelyLoading, setIsOptimizelyLoading] = useState(!!optimizelyProps?.optimizely)
        optimizelyProps?.optimizely?.onReady?.({ timeout: 2000 })?.then?.(() => {
          setIsOptimizelyLoading(false)
        })
        const showCarousel = (isEnabled && variationKey === 'v1_textcarouselwithbanner' && !isOptimizelyLoading) || forStoryBookUseOnly

        // Takeover component needs some transformation due to rules being applied through coremedia renderer,
        // so we had to manually add the additionalCTA and transform the pictures and media key (usually done my cm renderer)
        const takeoverItem = {
          ...item?.items?.[0],
          additionalCTA: item.additionalCTA,
          pictures: item?.items?.[0]?.picturesAndMedia.filter(({ type }) => type === 'image'),
          videos: item?.items?.[0]?.picturesAndMedia.filter(({ type }) => type === 'video')
        }

        return showCarousel ? (
          <Grid.Row>
            <Grid.Span small={{ width: 6, offset: 0 }} large={{ width: 12, offset: 0 }}>
              <SaleBannerTONABTest item={item?.items?.[1]} locale={locale} onTargetClick={onTargetClick} optimizely={optimizelyProps?.optimizely} />
            </Grid.Span>
          </Grid.Row>
        ) : (
          getControl({ tags: item?.tags, takeoverItem, locale, config, brand, optimizelyProps })
        )
      }}
    </OptimizelyFeature>
  )
}

TONSaleBannerABTest.layoutVariant = ['osc-ton-sale-banner-ab-test']
export default withOptimizely(TONSaleBannerABTest)

TONSaleBannerABTest.propTypes = {
  locale: shape({
    language: string.isRequired
  }).isRequired,
  item: shape({
    preTitlePlain: string.isRequired,
    subTitlePlain: string,
    teaserTitle: string.isRequired,
    teaserTextPlain: string.isRequired,
    pictures: array,
    layoutVariant: string.isRequired,
    target: shape({
      href: string.isRequired,
      teaserTitle: string.isRequired,
      teaserTextPlain: string.isRequired
    }).isRequired
  }),
  onTargetClick: func.isRequired
}
